import React from "react"

import styles from "./style.module.less"

const PageCenter = ({ children, className }) => {
  return (
    <div className={className ? className : styles.page__center}>
      {children}
    </div>
  )
}
export default PageCenter
