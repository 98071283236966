import React from "react"
import _truncate from "lodash/truncate"
import _toLower from "lodash/toLower"
import _size from "lodash/size"
import _debounce from "lodash/debounce"
import { Input } from "antd"

import { Icon } from "@components/Icons"
import CategorySelect from "@components/CategorySelect"

import "./styles.less"

const SearchInput = ({
  handleSearch,
  handleLoading,
  handleChangeCategory,
  options,
}) => {
  const [param, setParam] = React.useState("")
  const [selectedCategory, setSelectedCategory] = React.useState("Todos")

  const debounceSearch = React.useCallback(
    _debounce((searchParam) => makeSearch({ searchParam }), 1500),
    []
  )

  const handleCleanSearch = () => {
    setParam("")
    setSelectedCategory("Todos")
    handleSearch({ searchParam: "", category: "all" })
  }
  const onInputChangeHandler = (e) => {
    const newParam = e.target.value
    if (!newParam) {
      return handleCleanSearch()
    }
    setParam(newParam)
    debounceSearch(newParam)
  }

  const makeSearch = async ({ category, searchParam = param }) => {
    if (_size(searchParam) === 1) return
    const cleanedCategory = _toLower(category)
    if (!searchParam && !cleanedCategory) return
    if (category) setSelectedCategory(category)

    handleLoading(true)

    if (!searchParam && cleanedCategory) {
      await handleChangeCategory(
        cleanedCategory === "todos" ? "all" : cleanedCategory
      )
      return
    }

    handleSearch({ searchParam, category: cleanedCategory })
  }

  const handleSearchOnEnter = (e) =>
    e.key === "Enter" && param ? debounceSearch(param) : null
  return (
    <section className="justify-start custom_search">
      <div className="flex flex-row custom_search_input relative">
        <Input
          className="custom_search_input_container b"
          placeholder="Pesquisar"
          onChange={onInputChangeHandler}
          prefix={<Icon name="SearchIcon" width={20} height={20} />}
          value={param}
          onKeyPress={handleSearchOnEnter}
        />
        {!!param && (
          <Icon
            name="CloseSearch"
            onClick={handleCleanSearch}
            className="absolute z-4 close_icon pointer"
          />
        )}
        <CategorySelect
          options={options}
          makeSearch={makeSearch}
          currentSelected={selectedCategory}
          setCurrentSelected={setSelectedCategory}
        />
      </div>
    </section>
  )
}

export default SearchInput
