import React from "react"
import cx from "classnames"
import _map from "lodash/map"
import { Icon } from "@components/Icons"

import "./styles.less"

const CategorySelect = ({
  makeSearch,
  currentSelected,
  setCurrentSelected,
  options,
}) => {
  const wrapperRef = React.useRef(null)

  const [isOpenSelect, setIsOpenSelect] = React.useState(false)

  const [isChevronUp, setIsChevronUp] = React.useState(false)

  const [isFadingOut, setIsFadingOut] = React.useState(false)

  const handleFadeOut = (cb) => {
    setIsFadingOut(true)
    setTimeout(() => {
      cb()
      setIsFadingOut(false)
    }, 300)
  }

  const handleToggleSelect = () => {
    setIsOpenSelect(!isOpenSelect)
    setIsChevronUp(!isChevronUp)
  }
  const handleOpenSelect = () => {
    if (isOpenSelect) {
      return handleFadeOut(handleToggleSelect)
    }
    handleToggleSelect()
  }

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpenSelect(false)
        setIsChevronUp(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [wrapperRef])

  const handleChange = (category) => {
    setCurrentSelected(category)
    makeSearch({ category })
  }

  return (
    <div
      ref={wrapperRef}
      onClick={handleOpenSelect}
      className="pointer selectNotBordered"
    >
      <div className="selectedCat">{currentSelected || "Todos"}</div>
      <div>
        <Icon
          name="ChevronDown"
          className={cx("pointer", { "rotate-180": !isChevronUp })}
          width={10}
          height={7}
        />
      </div>
      <ul
        className={cx(
          { "custom_dropdown fade-in-top": isOpenSelect },
          { "fade-in-bot": isFadingOut },
          { dn: !isFadingOut && !isOpenSelect }
        )}
      >
        {_map(options, ({ label, value }) => (
          <li
            className={cx({ option_active: currentSelected === label })}
            onClick={() => handleChange(label)}
            key={value}
          >
            {label}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CategorySelect
