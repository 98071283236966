import React from "react"

import styles from "./style.module.less"

const Loading = ({ marginTop }) => {
  return (
    <div className={styles.loading}>
      <img
        className={styles.loading__img}
        style={{ marginTop: marginTop || "50px" }}
        src={require(`@images/loading.gif`)}
      />
    </div>
  )
}
export default Loading
